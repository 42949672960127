<template>
  <section class="present-cards">
    <h2 class="title">Cards</h2>
    <ul class="list" v-if="receiveGifts !== undefined">
      <li class="list__item" v-for="elem in receiveGifts[currentPage]" :key="elem.id">
        <div class="card-products small-card">
          <img :src="elem.image.url"/>
          <div class="wrapper-desc">
            <h5 class="product-name" @click="transitionSelectGift(elem.id)">{{ elem.title }}</h5>
            <h5 class="product-price">€ {{ elem.price/100 }}</h5>
          </div>
        </div>
      </li>
    </ul>
    <div class="product-pagination">
      <div class="quantity-elements">
        Showing 11 products from 150
      </div>
      <div v-if="receiveGifts !== undefined">
        <sliding-pagination
            :current="currentPage + 1"
            :total="receiveGifts.length"
            @page-change="pageChangeHandler"
        ></sliding-pagination>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PresentCards",
  data(){
    return{
      currentPage:0,
    }
  },
  watch:{
    '$route.params.productId':{
      handler(productId){
        this.$store.dispatch('getSelectProduct',productId)
      },
      deep:true,
      immediate:true,
    }
  },
  computed:{
    receiveGifts(){
      const paginationArray = this.$store.getters['receiveGifts']?.reduce((accumulate,currentValue)=>{
        if(accumulate[accumulate.length-1].length == 9){
          accumulate.push([]);
        }
        accumulate[accumulate.length-1].push(currentValue);
        return accumulate;
      }, [[]]);
      return paginationArray;
    }
  },
  components:{
    SlidingPagination:()=> import('vue-sliding-pagination'),
  },
  methods:{
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage -1;
    },
    transitionSelectGift(id){
      this.$store.dispatch('getSelectGift',id);
      this.$router.push({name:'selectPresentCards',params:{cardId:id}})
    },
  },
  created() {
    this.$store.dispatch('getGiftsProducts')
  }
}
</script>

<style scoped lang="scss">
@import "PresentCards";
</style>